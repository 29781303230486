<template>
  <b-row>
    <b-col lg="6" md="12">
      <div class="theme-font-regular font-16" v-html="expandedVwTxt"></div>
    </b-col>
    <b-col lg="6" md="12">
      <div class="calendar-box">
        <CalendarEventsList :calendar-events-list="content.eventsResults" :limit="limit" :show-date="true" :show-error="true"></CalendarEventsList>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'Calendar-two-column-list',
  components: {
    CalendarEventsList: () => import('./CalendarEventsList.vue'),
  },
  props: {
    content: { type: Object },
    limit: [Number, String],
    expandedVwTxt: { type: String },
  },
}
</script>
<style scoped lang="scss">
.calendar-box {
  border: 1px solid #f0f0f0;
  padding: 0px 10px;
  border-radius: 4px;
}

::v-deep .event-date {
  padding-left: 20px;
}
</style>
